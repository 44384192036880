<template>
  <nav class="navbar" :style="{ backgroundColor: backgroundColor, color: textColor }">
    <!-- Desktop View -->
    <div class="desktop-nav" v-if="!isMobile">
      <router-link :to="{ path: '/', style: { color: textColor } }" class="logo">
        <h1>B<span>oo</span>ksVille</h1>
      </router-link>

      <template v-if="!showOnlyLogo">
        <ul v-if="showNavElements" :class="{ active: isMenuOpen }" class="nav-links">
          <li>
            <router-link :to="{ path: '/home', style: { color: textColor } }">
              Home
            </router-link>
          </li>
          <li>
            <router-link :to="{ path: '/books', style: { color: textColor } }">
              Book Shop
            </router-link>
          </li>
          <li>
            <router-link :to="{ path: '/social', style: { color: textColor } }">
              Social
            </router-link>
          </li>
          <li>
            <router-link :to="{ path: '/about', style: { color: textColor } }">
              About
            </router-link>
          </li>
        </ul>

        <div v-if="showNavElements" class="icons">
          <div class="profile-dropdown" ref="profileDropdown">
            <div class="profile-trigger" @click="toggleDropdown">
              <img :src="userProfile.profile_picture" :alt="userProfile.first_name || 'Profile'"
                class="profile-image" />
              <i class="fa-solid fa-angle-down dropdown-icon" :class="{ rotate: isDropdownOpen }"></i>
            </div>
            <div v-show="isDropdownOpen" class="dropdown-menu">
              <router-link :to="`/account/reading`" class="menu-item">
                <span>Account</span>
              </router-link>
              <router-link :to="`/profile/${userProfile.id}`" class="menu-item">
                <span>Profile</span>
              </router-link>
              <button @click="handleLogout" class="menu-item">
                <span>Logout</span>
              </button>
            </div>
          </div>
          <router-link :to="{ path: '/search', style: { color: textColor } }">
            <i class="fa-solid fa-magnifying-glass"></i>
          </router-link>
          <router-link :to="{ path: '/cart', style: { color: textColor } }">
            <i class="fa-solid fa-cart-shopping"></i>
          </router-link>
        </div>
      </template>
    </div>

    <!-- Mobile View -->
    <div class="mobile-nav" v-else>
      <div class="mobile-top-bar">
        <router-link :to="{ path: '/', style: { color: textColor } }" class="mobile-logo">
          <img src="@/assets/img/booksvillelogodark.webp" alt="BooksVille" />
        </router-link>

        <template v-if="!showOnlyLogo && showNavElements">
          <div class="mobile-actions">
            <router-link :to="{ path: '/search', style: { color: textColor } }">
              <i class="fa-solid fa-magnifying-glass"></i>
            </router-link>
            <router-link :to="{ path: '/cart', style: { color: textColor } }">
              <i class="fa-solid fa-cart-shopping"></i>
            </router-link>
            <button @click="toggleMobileMenu" class="mobile-profile">
              <img :src="userProfile.profile_picture" :alt="userProfile.first_name || 'Profile'"
                class="profile-image" />
            </button>
          </div>
        </template>
      </div>

      <div v-if="!showOnlyLogo && showNavElements" class="mobile-bottom-bar">
        <router-link to="/home" class="nav-item">
          <i class="fa-solid fa-house"></i>
          <span>Home</span>
        </router-link>
        <router-link to="/books" class="nav-item">
          <i class="fa-solid fa-book"></i>
          <span>Books</span>
        </router-link>
        <router-link to="/social" class="nav-item">
          <i class="fa-solid fa-users"></i>
          <span>Social</span>
        </router-link>
        <router-link to="/account" class="nav-item">
          <i class="fa-solid fa-user"></i>
          <span>Account</span>
        </router-link>
      </div>

      <!-- Mobile Menu Bottom Sheet -->
      <div v-if="isMobileMenuOpen" class="mobile-menu-overlay" @click="closeMobileMenu"></div>
      <div class="mobile-menu-sheet" :class="{ 'is-active': isMobileMenuOpen }">
        <div class="mobile-menu-header">
          <img :src="userProfile.profile_picture" :alt="userProfile.first_name || 'Profile'"
            class="mobile-menu-profile" />
          <div class="mobile-menu-user-info">
            <h3>{{ userProfile.first_name }} {{ userProfile.last_name }}</h3>
            <p>{{ userProfile.email }}</p>
          </div>
          <button @click="closeMobileMenu" class="mobile-menu-close">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div class="mobile-menu-items">
          <router-link :to="`/profile/${userProfile.id}`" class="mobile-menu-item" @click="closeMobileMenu">
            <i class="fa-solid fa-user"></i>
            <span>View Profile</span>
          </router-link>
          <button @click="handleMobileLogout" class="mobile-menu-item">
            <i class="fa-solid fa-sign-out-alt"></i>
            <span>Logout</span>
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import api from '@/api/axios'
import { RouterLink } from 'vue-router'
import { useStore } from 'vuex'
import { computed, ref, onMounted, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'

export default {
  name: 'Navbar',

  setup() {
    const store = useStore()
    const route = useRoute()
    const isMobile = ref(window.innerWidth <= 768)
    const isDropdownOpen = ref(false)
    const isMobileMenuOpen = ref(false)
    const profileDropdown = ref(null)
    const isMenuOpen = ref(false)

    const showNavElements = computed(() => {
      const isAuthenticated = store.getters.isAuthenticated
      const currentRoute = window.$router?.currentRoute.value.name
      const authRoutes = ['login', 'signup', 'landing']
      return isAuthenticated && !authRoutes.includes(currentRoute)
    })

    const showOnlyLogo = computed(() => {
      const isTermsPage = route.path === '/terms'
      const isAuthenticated = store.getters.isAuthenticated
      return isTermsPage && !isAuthenticated
    })

    const handleResize = () => {
      isMobile.value = window.innerWidth <= 768
    }

    const handleClickOutside = (event) => {
      if (profileDropdown.value && !profileDropdown.value.contains(event.target)) {
        isDropdownOpen.value = false
      }
    }

    onMounted(() => {
      window.addEventListener('resize', handleResize)
      document.addEventListener('click', handleClickOutside)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
      document.removeEventListener('click', handleClickOutside)
    })

    return {
      showNavElements,
      showOnlyLogo,
      isMobile,
      isDropdownOpen,
      isMobileMenuOpen,
      profileDropdown,
      isMenuOpen,
    }
  },

  data() {
    return {
      backgroundColor: '#f8f9fa',
      textColor: '#333',
      userProfile: {
        profile_picture: '',
        first_name: '',
        last_name: '',
        email: '',
        id: null,
      },
    }
  },

  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen
    },

    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen
      if (this.isMobileMenuOpen) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    },

    closeMobileMenu() {
      this.isMobileMenuOpen = false
      document.body.style.overflow = ''
    },

    async handleLogout() {
      try {
        await this.$store.dispatch('logout')
        this.isDropdownOpen = false
      } catch (error) {
        console.error('Error during logout:', error)
      }
    },

    async handleMobileLogout() {
      try {
        await this.$store.dispatch('logout')
        this.closeMobileMenu()
      } catch (error) {
        console.error('Error during logout:', error)
      }
    },

    async fetchUserProfile() {
      try {
        const response = await api.get('/users/profile/')
        if (response.data.success) {
          this.userProfile = response.data.data
        }
      } catch (error) {
        console.error('Error fetching user profile:', error)
      }
    },
  },

  watch: {
    showNavElements(newVal) {
      if (newVal) {
        this.fetchUserProfile()
      }
    },
  },

  mounted() {
    if (this.showNavElements) {
      this.fetchUserProfile()
    }
  },
}
</script>

<style scoped>
.navbar {
  display: flex;
  position: sticky;
  top: 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  z-index: 1000;
  transition: background-color 0.3s ease, color 0.3s ease, padding 0.3s ease;
}

/* Desktop Styles */
.desktop-nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.logo {
  text-decoration: none;
}

.logo h1 {
  margin: 0;
  font-size: 1.8rem;
  font-weight: 700;
  cursor: pointer;
  user-select: none;
}

.logo span {
  color: #f27cf9;
}

.nav-links {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 2rem;
}

.nav-links li {
  margin: 0;
}

.nav-links a {
  text-decoration: none;
  color: inherit;
  font-weight: 500;
  font-size: 1rem;
  transition: color 0.2s ease;
  position: relative;
}

.nav-links a:after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -4px;
  left: 0;
  background-color: #f27cf9;
  transition: width 0.3s ease;
}

.nav-links a:hover:after {
  width: 100%;
}

.icons {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.icons a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s ease;
}

.icons a:hover {
  transform: translateY(-2px);
}

.icons a i {
  font-size: 1.2rem;
}

/* Profile Dropdown Styles */
.profile-dropdown {
  position: relative;
}

.profile-trigger {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.profile-image {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #f27cf9;
  padding: 2px;
}

.dropdown-icon {
  position: absolute;
  right: -2px;
  bottom: -2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: #333;
  transition: transform 0.2s;
  transform-origin: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.dropdown-icon.rotate {
  transform: rotate(180deg);
}

.dropdown-menu {
  position: absolute;
  top: calc(100% + 8px);
  right: -10px; /* Added negative right margin for better alignment */
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 160px;
  padding: 8px 0;
  z-index: 1000;
  animation: slideDown 0.2s ease forwards;
}

.menu-item {
  all: unset;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 16px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s ease;
  box-sizing: border-box;
  text-align: left; /* Added for alignment */
}

.menu-item:hover {
  background-color: #f8f9fa;
}

.menu-item span {
  display: block;
  width: 100%; /* Makes the clickable area full width */
  padding: 0 4px; /* Added small padding for better spacing */
}

/* Mobile Styles */
.mobile-nav {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.mobile-top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.mobile-logo img {
  height: 32px;
  width: auto;
}

.mobile-actions {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.mobile-actions a {
  color: inherit;
  text-decoration: none;
}

.mobile-actions i {
  font-size: 1.2rem;
}

.mobile-profile {
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}

.mobile-profile img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid #f27cf9;
  padding: 1px;
}

.mobile-bottom-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  padding: 8px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #666;
  gap: 4px;
  padding: 4px 12px;
  transition: color 0.2s ease;
}

.nav-item.router-link-active {
  color: #f27cf9;
}

.nav-item i {
  font-size: 1.2rem;
}

.nav-item span {
  font-size: 0.75rem;
  font-weight: 500;
}

/* Mobile Menu Sheet Styles */
.mobile-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  animation: fadeIn 0.2s ease forwards;
}

.mobile-menu-sheet {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border-radius: 16px 16px 0 0;
  z-index: 1001;
  transform: translateY(100%);
  transition: transform 0.3s ease;
  max-height: 90vh;
  overflow-y: auto;
}

.mobile-menu-sheet.is-active {
  transform: translateY(0);
}

.mobile-menu-header {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #eee;
  position: relative;
}

.mobile-menu-profile {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #f27cf9;
  margin-right: 12px;
}

.mobile-menu-user-info {
  flex: 1;
}

.mobile-menu-user-info h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.mobile-menu-user-info p {
  margin: 4px 0 0;
  font-size: 14px;
  color: #666;
}

.mobile-menu-close {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 20px;
  color: #666;
  cursor: pointer;
  padding: 4px;
}

.mobile-menu-items {
  padding: 12px 0;
}

.mobile-menu-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 20px;
  background: none;
  border: none;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  text-decoration: none;
}

.mobile-menu-item i {
  margin-right: 12px;
  width: 20px;
  color: #666;
}

/* Animations */
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Responsive adjustments */
@media (max-width: 1024px) and (min-width: 769px) {
  .nav-links {
    gap: 1.5rem;
  }

  .icons {
    gap: 1.5rem;
  }

  .logo h1 {
    font-size: 1.6rem;
  }
}

@media (max-width: 768px) {
  .navbar {
    padding: 0;
  }

  .mobile-top-bar {
    padding: 8px 16px;
  }

  .mobile-actions i {
    font-size: 1.1rem;
  }

  main {
    padding-bottom: 60px;
  }
}

@media (max-width: 480px) {
  .mobile-logo img {
    height: 28px;
  }

  .mobile-actions {
    gap: 1rem;
  }

  .mobile-profile img {
    width: 28px;
    height: 28px;
  }

  .nav-item i {
    font-size: 1.1rem;
  }

  .nav-item span {
    font-size: 0.7rem;
  }

  .mobile-menu-profile {
    width: 40px;
    height: 40px;
  }

  .mobile-menu-user-info h3 {
    font-size: 15px;
  }

  .mobile-menu-user-info p {
    font-size: 13px;
  }

  .mobile-menu-item {
    font-size: 15px;
    padding: 14px 20px;
  }
}

/* Fix for bottom bar overlap with content */
body {
  padding-bottom: env(safe-area-inset-bottom);
}
</style>