import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/auth'
import axios from 'axios'
import './assets/transitions.css'

// Add these imports
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

const app = createApp(App)

const toastOptions = {
  position: 'top-right',
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
  rtl: false,
}

store.dispatch('initializeAuth').finally(() => {
  app.config.globalProperties.$axios = axios

  app.use(store)
  app.use(router)
  app.use(Toast, toastOptions)
  app.mount('#app')
})
