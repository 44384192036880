<template>
  <!-- this is the real life -->
  <footer class="footer">
    <div class="footer-main">
      <!-- Left Side - Illustration -->
      <div class="newsletter-container">
        <div class="illustration">
          <img src="@/assets/img/tilesbook.png" alt="Girl reading" class="reading-illustration" />
          <h2 class="brand-text">BOOKSVILLE</h2>
        </div>
      </div>

      <!-- Right Side - Content and Links -->
      <div class="content-links-container">
        <!-- Newsletter Content -->
        <div class="newsletter-content">
          <h2>
            Get the latest news and information about your favorite authors or
            books
          </h2>
          <div class="newsletter-form">
            <input type="email" placeholder="Enter your email address" class="email-input" v-model="email" />
            <button class="signup-btn" @click="handleSignup">Sign up</button>
          </div>
        </div>

        <!-- Footer Links -->
        <div class="footer-links">
          <div class="footer-section">
            <h3>BOOKSVILLE</h3>
            <ul>
              <li>498 Anywhere St., Any City</li>
              <li>87990, Any State</li>
              <li>123-456-7890</li>
              <li>support@booksville.com</li>
            </ul>
          </div>

          <div class="footer-section">
            <h3>EXPLORE</h3>
            <ul>
              <li><router-link to="/about">About Us</router-link></li>
              <li><router-link to="/terms">Terms & Conditions</router-link></li>
              <li><router-link to="/rental-policy">Rental Policy</router-link></li>
              <li><router-link to="/contact">Contact Us</router-link></li>
            </ul>
          </div>

          <div class="footer-section">
            <h3>FOLLOW US</h3>
            <ul>
              <li class="h">Roadmap</li>
              <li class="h">Premium</li>
              <li class="h">Book Club+</li>
              <li class="h">Launching Soon</li>
            </ul>
            <div class="social-icons">
              <a href="#" aria-label="Facebook"><i class="fab fa-facebook-f"></i></a>
              <a href="#" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
              <a href="#" aria-label="GitHub"><i class="fab fa-github"></i></a>
              <a href="#" aria-label="LinkedIn"><i class="fab fa-linkedin-in"></i></a>
            </div>
          </div>
        </div>
      </div>

      <!-- Copyright -->
      <div class="copyright">
        <p>Copyright © 2024 BOOKSVILLE - A House of Books</p>
      </div>
    </div>
  </footer>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'Footer',
  setup() {
    const email = ref('')

    const handleSignup = () => {
      // Add newsletter signup logic here
      if (email.value && email.value.includes('@')) {
        console.log('Signing up with email:', email.value)
        // Add API call or other signup logic
        email.value = '' // Clear the input after signup
      }
    }

    return {
      email,
      handleSignup,
    }
  },
}
</script>

<style scoped>
.footer {
  background-color: #2b2668;
  color: white;
  width: 100%;
}

.footer-main {
  max-width: 1440px;
  margin: 0 auto;
  padding: 4rem 20px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 4rem;
}

/* Left Side - Newsletter and Illustration */
.newsletter-container {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  padding-right: 4rem;
}

.illustration {
  margin-bottom: 2rem;
}

.reading-illustration {
  width: 100%;
  max-width: 400px;
  height: auto;
}

.h {
  cursor: pointer;
}

.h:hover {
  color: white;
}

.brand-text {
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  margin-top: 2rem;
  text-align: left;
  font-family: sans-serif;
  letter-spacing: 1px;
}

/* Right Side - Content and Links */
.content-links-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.newsletter-content {
  margin-bottom: 4rem;
}

.newsletter-content h2 {
  font-size: 2.5rem;
  line-height: 1.3;
  font-weight: 600;
  color: white;
  margin: 0 0 2rem 0;
}

.newsletter-form {
  display: flex;
  gap: 1rem;
  margin-bottom: 4rem;
}

.email-input {
  flex: 1;
  padding: 1rem 1.5rem;
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 1rem;
  min-width: 0;
}

.email-input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.signup-btn {
  padding: 1rem 2rem;
  background-color: #ff8fe5;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  white-space: nowrap;
  transition: background-color 0.3s ease;
}

.signup-btn:hover {
  background-color: #ff7ce0;
}

/* Footer Links Section */
.footer-links {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
}

.footer-section h3 {
  color: white;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  letter-spacing: 0.5px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer-section ul li {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  line-height: 1.5;
}

.footer-section a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  transition: color 0.2s ease;
}

.footer-section a:hover {
  color: white;
}

.social-icons {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.social-icons a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  transition: color 0.2s ease;
}

.social-icons a:hover {
  color: white;
}

.copyright {
  grid-column: 1 / -1;
  text-align: center;
  padding-top: 2rem;
  margin-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.copyright p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .footer-main {
    gap: 2rem;
  }

  .newsletter-content h2 {
    font-size: 2rem;
  }
}

@media (max-width: 1024px) {
  .footer-main {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .newsletter-container {
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-right: 0;
    padding-bottom: 3rem;
    text-align: center;
  }

  .illustration {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .reading-illustration {
    max-width: 300px;
  }

  .brand-text {
    text-align: center;
  }

  .newsletter-form {
    max-width: 500px;
    margin: 0 auto 2rem;
  }
}

@media (max-width: 768px) {
  .footer-main {
    padding: 3rem 20px;
  }

  .footer-links {
    grid-template-columns: 1fr;
    gap: 2.5rem;
    text-align: center;
  }

  .newsletter-form {
    flex-direction: column;
  }

  .social-icons {
    justify-content: center;
  }

  .newsletter-content h2 {
    font-size: 1.75rem;
  }
}

@media (max-width: 480px) {
  .footer-main {
    padding: 2rem 20px;
  }

  .newsletter-content h2 {
    font-size: 1.5rem;
  }

  .reading-illustration {
    max-width: 250px;
  }
}
</style>
