<template>
  <div id="app">
    <Navbar :showOnlyLogo="shouldShowOnlyLogo" />
    <Suspense>
      <template #default>
        <router-view v-if="initialized" />
      </template>
      <template #fallback>
        <div class="loading-container">
          <div class="loading-spinner"></div>
        </div>
      </template>
    </Suspense>
    <Footer v-if="!hideFooter && initialized" />
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import Navbar from './components/navbar/Navbar.vue'
import Footer from './components/footer/Footer.vue'

export default {
  name: 'App',
  components: {
    Footer,
    Navbar,
  },
  setup() {
    const store = useStore()
    const initialized = ref(false)

    const publicOnlyRoutes = ref([
      '/terms',
      '/about',
      '/privacy',
      '/contact',
      '/',
    ])

    const routesWithoutFooter = ref(['/account', '/login', '/signup'])

    onMounted(async () => {
      await store.dispatch('initializeAuth')
      initialized.value = true
    })

    const shouldShowOnlyLogo = computed(() => {
      const currentPath = store.$router?.currentRoute.value?.path
      const isAuthenticated = store.getters.isAuthenticated
      return publicOnlyRoutes.value.includes(currentPath) && !isAuthenticated
    })

    const hideFooter = computed(() => {
      const currentPath = store.$router?.currentRoute.value?.path
      return routesWithoutFooter.value.includes(currentPath)
    })

    return {
      initialized,
      shouldShowOnlyLogo,
      hideFooter,
      publicOnlyRoutes,
      routesWithoutFooter,
    }
  },
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Sora', sans-serif;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #282772;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
